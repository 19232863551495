import { MAX_EVENT_LIST_SIZE_IN_BYTES, MAX_INTERVAL, MIN_INTERVAL } from '../constants';
var BaseEventsStore = /** @class */function () {
  function BaseEventsStore(args) {
    var _this = this;
    var _a, _b, _c;
    this.minInterval = MIN_INTERVAL;
    this.maxInterval = MAX_INTERVAL;
    this.maxPersistedEventsSize = MAX_EVENT_LIST_SIZE_IN_BYTES;
    this.interval = this.minInterval;
    this._timeAtLastSplit = Date.now(); // Initialize this so we have a point of comparison when events are recorded
    /**
     * Determines whether to send the events list to the backend and start a new
     * empty events list, based on the size of the list as well as the last time sent
     * @param nextEventString
     * @returns boolean
     */
    this.shouldSplitEventsList = function (events, nextEventString) {
      var sizeOfNextEvent = new Blob([nextEventString]).size;
      var sizeOfEventsList = new Blob(events).size;
      if (sizeOfEventsList + sizeOfNextEvent >= _this.maxPersistedEventsSize) {
        return true;
      }
      if (Date.now() - _this.timeAtLastSplit > _this.interval && events.length) {
        _this.interval = Math.min(_this.maxInterval, _this.interval + _this.minInterval);
        _this._timeAtLastSplit = Date.now();
        return true;
      }
      return false;
    };
    this.loggerProvider = args.loggerProvider;
    this.minInterval = (_a = args.minInterval) !== null && _a !== void 0 ? _a : this.minInterval;
    this.maxInterval = (_b = args.maxInterval) !== null && _b !== void 0 ? _b : this.maxInterval;
    this.maxPersistedEventsSize = (_c = args.maxPersistedEventsSize) !== null && _c !== void 0 ? _c : this.maxPersistedEventsSize;
  }
  Object.defineProperty(BaseEventsStore.prototype, "timeAtLastSplit", {
    get: function () {
      return this._timeAtLastSplit;
    },
    enumerable: false,
    configurable: true
  });
  return BaseEventsStore;
}();
export { BaseEventsStore };
